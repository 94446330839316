import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PublicHeader from '../../components/layout/Header/PublicHeader';
import PublicFooter from '../../components/layout/Footer/PublicFooter';
import countrysideImage from '../../assets/image/panglao.webp';
import joinerImage from '../../assets/image/panglao.webp';
import islandHoppingImage from '../../assets/image/panglao.webp';
import fireflyImage from '../../assets/image/panglao.webp';
import classes from './BoholTourDetails.module.css';

const tourData = {
  tours: [
    {
      id: 1,
      tour_type: 'countryside',
      title: 'Countryside Tour',
      location: 'Bohol, Philippines',
      bookings: '500++ booked',
      price: 'PHP 2,500',
      image: countrysideImage,
      buttonText: 'Book Now',
    },
    {
      id: 2,
      tour_type: 'joiner',
      title: 'Joiner Tour',
      location: 'Bohol, Philippines',
      bookings: '1500++ booked',
      price: 'PHP 980',
      image: joinerImage,
      buttonText: 'Book Now',
    },
    {
      id: 3,
      tour_type: 'island',
      title: 'Island Hopping',
      location: 'Panglao, Philippines',
      bookings: '2100++ booked',
      price: 'PHP 1,100',
      image: islandHoppingImage,
      buttonText: 'Book Now',
    },
    {
      id: 4,
      tour_type: 'firefly',
      title: 'Firefly Watching',
      location: 'Bohol, Philippines',
      bookings: '1000++ booked',
      price: 'PHP 980',
      image: fireflyImage,
      buttonText: 'Book Now',
    },
  ]
};

const BoholTourDetails = () => {
  const [activeTab, setActiveTab] = useState('countryside');
  const navigate = useNavigate();

  const handleSeeMore = (tourType) => {
    // Navigate to the tour details page
    navigate(`/tour-details/${tourType}`);
  };

  return (
    <>
      <PublicHeader />
      <section className={classes.tourDetails}>
        <h2>Bohol Tour Packages</h2>
        <div className={classes.tourGrid}>
          {tourData.tours.map((tour) => (
            <div key={tour.id} className={classes.tourCard}>
              <img src={tour.image} alt={tour.title} className={classes.tourImage} />
              <div className={classes.tourContent}>
                <p className={classes.tourLocation}>{tour.location}</p>
                <h4 className={classes.tourTitle}>{tour.title}</h4>
                <p className={classes.tourBookings}>{tour.bookings}</p>
                <span className={classes.tourPrice}>Price: {tour.price}</span>
                <div className={classes.tourButtons}>
                  <button className={classes.bookButton}>{tour.buttonText}</button>
                  <button className={classes.seeMoreButton} onClick={() => handleSeeMore(tour.tour_type)}>See More</button>
                </div>

              </div>
            </div>
          ))}
        </div>
      </section>
      <PublicFooter />
    </>
  );
};

export default BoholTourDetails;
