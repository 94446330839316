import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import classes from './PublicHeader.module.css';
import Logo from '../../../assets/image/logo/navbar.png';
import { FaPhoneAlt, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';
import { handleScroll, toggleMenu, scrollToSection, openGallery, openServices } from './HeaderController';

const PublicHeader = () => {
  const [isFloating, setIsFloating] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const cleanupScroll = handleScroll(setIsFloating);

    return cleanupScroll;
  }, []);

  return (
  <header className={`${classes.header} ${isFloating ? classes.floating : ''}`}>
    <div className={classes.topBar}>
      <img src={Logo} alt='Logo' className={classes.logo} />
      <div className={classes.contactInfo}>
        <span><FaPhoneAlt /> +63 938 609 1908</span>
        <span><FaEnvelope /> travel.jl@yahoo.com</span>
        <span><FaMapMarkerAlt /> Tagbilaran City, Philippines</span>
      </div>
      <div className={classes.actions}>
        <button className={classes.professionalsButton}>View our Travel Professionals</button>
      </div>
    </div>
    <div className={classes.mobileMenu}>
      <span><FaPhoneAlt /> +63 938 609 1908</span>
      <button className={classes.burger} onClick={() => toggleMenu(isMenuOpen, setIsMenuOpen)}>
        ☰
      </button>
    </div>
    
    <nav className={`${classes.nav} ${isMenuOpen ? classes.open : ''}`}>
      {isMenuOpen && (
        <button className={classes.closeButton} onClick={() => toggleMenu(isMenuOpen, setIsMenuOpen)}>
          ✕
        </button>
      )}
      <ul className={classes.navList}>
        <li className={classes.navItem} onClick={() => navigate('/')}>Begin Your Journey</li>
        <li className={classes.navItem} onClick={() => navigate('/services')}>Services</li>
        <li className={classes.navItem} onClick={() => navigate('/tour-packages')}>Tour Packages</li>
        <li className={classes.navItem} onClick={() => openGallery()}>Gallery</li>          
        <li className={classes.navItem} onClick={() => scrollToSection('about-section', setIsMenuOpen, navigate)}>About Us</li>
        <li className={classes.navItem} onClick={() => scrollToSection('faq-section', setIsMenuOpen, navigate)}>FAQ</li>
      </ul>
    </nav>
    <ToastContainer position={toast.POSITION.TOP_CENTER} />
  </header>

  );
};

export default PublicHeader;

