import React from 'react';
import classes from './Hero.module.css';

const Hero = () => {
    const handleExploreClick = () => {
        // window.location.href = `${BASE_URL}/destinations`; // Adjust this URL as necessary
    };    

    return (
        <section className={classes.hero}>
            <div className={classes.heroContent}>
            <h1>Your Adventure Awaits</h1>
            <p>Discover the world with JL Travel Agency and make memories that last a lifetime.</p>
            <button onClick={handleExploreClick} className={classes.ctaButton}>Explore Destinations</button>
            </div>
        </section>
    );
};

export default Hero;