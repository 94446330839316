import React from 'react';
import classes from './GoogleReviews.module.css';
import review1 from '../../assets/image/reviews/1.jpg';
import review2 from '../../assets/image/reviews/2.jpg';
import review3 from '../../assets/image/reviews/3.jpg';

const GoogleReviews = () => {
  const placeId = "ChIJ1TYB2JpNqjMRg0mzAutQwUo"; 
  return (
    <section className={classes.reviews}>
      <h3>See What Our Customers Are Saying</h3>
      <div className={classes.reviewImages}>
        <img src={review1} alt="Customer Review 1" className={classes.reviewImage} />
        <img src={review2} alt="Customer Review 2" className={classes.reviewImage} />
        <img src={review3} alt="Customer Review 3" className={classes.reviewImage} />
      </div>
      <a
        href={`https://search.google.com/local/writereview?placeid=${placeId}`}
        target="_blank"
        rel="noopener noreferrer"
        className={classes.reviewLink}
      >
        Read Our Reviews on Google
      </a>
    </section>
  );
};

export default GoogleReviews;
