import React from 'react';
import classes from './TermsOfUse.module.css';
import PublicHeader from '../../components/layout/Header/PublicHeader';
import PublicFooter from '../../components/layout/Footer/PublicFooter';

const TermsOfUse = () => {
  return (
    <>
    <PublicHeader />
    <section className={classes.terms}>
      <h2>Terms of Use</h2>
      <p>
        Welcome to the JL Travel Agency website (the "Site"). We're delighted to have you here! Before you explore, we kindly ask you to take a moment to read our terms and conditions. By using our Site, you agree to follow these guidelines. Please note, we may update these terms occasionally, so it’s a good idea to check back every so often. If at any time you decide these terms aren’t for you, we completely understand, and ask that you discontinue using the Site and remove any information you may have saved.
      </p>

      <h3>Jurisdiction</h3>
      <p>
        Our Site is managed from the Philippines, and Philippine law governs these terms. If any part of these terms doesn’t work legally, the rest will still apply. We want to ensure that you have a positive experience, no matter where you are.
      </p>

      <h3>Use of Information</h3>
      <p>
        We value your feedback, questions, and ideas! If you share any of these with us through the Site, please know that we may use them to help improve our services. Your input isn’t considered confidential, and we won’t be obligated to compensate you for it. Your ideas help us serve you better, and we’re grateful for your contributions.
      </p>

      <h3>Disclaimer and Limitation of Liability</h3>
      <p>
        We strive to keep all information on our Site accurate and up-to-date, but please understand that some details might change. We encourage you to verify information about our services before making any decisions. While we work hard to keep the Site running smoothly, we can’t guarantee it will always be available or error-free. JL Travel Agency cannot be held responsible for any losses or damages that may result from using our Site. We hope you enjoy using our Site, but please do so at your own discretion.
      </p>

      <h3>Copyright and Trademarks</h3>
      <p>
        All content on this Site, including images and text, is protected by copyright. We’re happy for you to use our content for personal purposes, such as printing or downloading information for your own use. However, if you’d like to use any of our content for business or public purposes, please reach out to us for permission. All trademarks and logos on this Site belong to JL Travel Agency or are used with permission, and we kindly ask that you respect these rights.
      </p>
    </section>
    <PublicFooter />
    </>
  );
};

export default TermsOfUse;
