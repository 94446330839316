import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import classes from './Faq.module.css';

const Faq = () => {
  return (
    <section className={classes.faq} id="faq-section">
      <h2 className={classes.sectionTitle}>Have Questions?</h2>
      <Accordion className={classes.accordionRoot}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{ color: '#FFFFFF' }} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={classes.accordionSummary}
        >
          <Typography className={classes.question}>How do I book a tour with JL Travel Agency?</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <Typography>
            Booking a tour with us is simple! You can book directly through our website by selecting your desired tour package and filling out the necessary details. Alternatively, you can contact our customer service team, and they will assist you with the booking process.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion className={classes.accordionRoot}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{ color: '#FFFFFF' }} />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          className={classes.accordionSummary}
        >
          <Typography className={classes.question}>What payment options are available?</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <Typography>
            We offer a variety of payment options for your convenience, including credit/debit cards, bank transfers, and popular e-wallets like GCash and PayMaya. All payments are processed securely to ensure your safety.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion className={classes.accordionRoot}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{ color: '#FFFFFF' }} />}
          aria-controls="panel3a-content"
          id="panel3a-header"
          className={classes.accordionSummary}
        >
          <Typography className={classes.question}>Do you offer travel insurance?</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <Typography>
            Yes, we offer comprehensive travel insurance plans to ensure your peace of mind during your journey. You can add travel insurance to your booking during the checkout process, or inquire about it with our customer service team.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </section>
  );
};

export default Faq;
