import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import 'react-toastify/dist/ReactToastify.css';
import PublicHeader from '../../components/layout/Header/PublicHeader';
import PublicFooter from '../../components/layout/Footer/PublicFooter';
import Hero from '../Hero/Hero';
import Introduction from '../Introduction/Introduction';
import BoholTourDetails from '../BoholTourDetails/BoholTourDetails';
import GoogleReviews from '../GoogleReviews/GoogleReviews';
import About from '../About/About';
import Products from '../Products/Products';
import Faq from '../Faq/Faq';
import classes from './Homepage.module.css';
import { BASE_URL } from '../../utils/constants';
import boholTourPromo from '../../assets/image/promo/bohol-tour-promo.jpg';

const Homepage = () => {



  return (
    <div className={classes.container}>
      <Helmet>
        <title>JL Travel Agency - Bohol Tour Packages and Services</title>
        <meta name="description" content="Explore Bohol with JL Travel Agency. We offer airline booking, hotel booking, and exclusive Bohol tour packages including countryside tours, island hopping, and more. Prices start at just PHP 2,500. Book your next adventure today!" />
        <meta name="keywords" content="Bohol tour packages, Bohol countryside tour, Panglao island hopping, JL Travel Agency, travel agency, Bohol tours, Philippine tourism, affordable tours, Bohol itinerary" />
        <meta property="og:title" content="JL Travel Agency - Bohol Tour Packages and Services" />
        <meta property="og:description" content="Discover the beauty of Bohol with JL Travel Agency. We offer various tour packages including countryside tours, island hopping, and more. Prices start at PHP 2,500." />
        <meta property="og:image" content={boholTourPromo} />
        <meta property="og:url" content={`${BASE_URL}/tour-packages`} />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="JL Travel Agency - Bohol Tour Packages and Services" />
        <meta name="twitter:description" content="Book your Bohol adventure with JL Travel Agency. Explore our countryside tours, island hopping, and more starting at PHP 2,500." />
        <meta name="twitter:image" content={boholTourPromo} />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href={`${BASE_URL}/tour-packages`} />
      </Helmet>
      <PublicHeader />
      <Hero />
      <Introduction />
      <GoogleReviews />
      <Faq />
      <PublicFooter />
      <ToastContainer position={toast.POSITION.TOP_CENTER} />
    </div>
  );
};

export default Homepage;
