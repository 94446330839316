import { useNavigate } from 'react-router-dom';

export const handleScroll = (setIsFloating) => {
    const onScroll = () => {
      setIsFloating(window.scrollY > 0);
    };
  
    window.addEventListener('scroll', onScroll);
  
    // Clean up event listener on component unmount
    return () => window.removeEventListener('scroll', onScroll);
  };
  
  export const toggleMenu = (isMenuOpen, setIsMenuOpen) => {
    setIsMenuOpen(!isMenuOpen);
  };

  export const scrollToSection = (sectionId, setIsMenuOpen, navigate) => {

    // Navigate to homepage if the section is on the homepage
    if (window.location.pathname !== '/') {
      // Navigate to the homepage first, then scroll
      navigate('/'); // Navigates to homepage
    }
  
    setTimeout(() => {
      const section = document.getElementById(sectionId);
      if (section) {
        window.scrollTo({
          top: section.offsetTop - 90,
          behavior: 'smooth',
        });
      }
    }, 100); // Set a slight delay to allow the page to render
  
    setIsMenuOpen(false);
  };

  // export const scrollToSection = (sectionId, setIsMenuOpen) => {
  //   const section = document.getElementById(sectionId);
  //   if (section) {
  //     window.scrollTo({
  //       top: section.offsetTop - 90,
  //       behavior: 'smooth',
  //     });
  //   }
  //   setIsMenuOpen(false);
  // };
  
  export const openGallery = () => {
    const galleryUrl = "https://www.facebook.com/media/set/?set=a.2326243247659554&type=3";
    window.open(galleryUrl, "_blank", "noopener,noreferrer");
  };

 
  