import panglaoImage from '../../assets/image/panglao.webp';
import countrySide from '../../assets/image/country-side.webp';


const toursData = [
    {
      id: 1,
      tour_type: 'countryside',
      title: 'Countryside Tour',
      location: 'Bohol, Philippines',
      description: 'Explore the scenic countryside of Bohol. Visit historical landmarks, interact with wildlife, and enjoy the natural beauty of the island.',
      image: countrySide,
      price: 2500,
      bookings: '1000++ booked',
      inclusions: [
        'Fully Air Conditioned Vehicle w/ Fuel',
        'Licensed Tour Driver and Photographer',
        'Pick up Guests @ Any Point Along Panglao and Tagbilaran City',
        'Drop Guests @ Any Point Along Panglao and Tagbilaran City',
        'Tour Duration (08:00AM – 05:00PM)',
      ],
      exclusions: [
        'Entrance Fees',
        'Lunch @ Loboc or Loay River Floating Restaurant (Cruise)',
        'All Rides',
      ],
    },
    {
      id: 2,
      tour_type: 'joiner',
      title: 'Joiner Tour',
      location: 'Bohol, Philippines',
      description: 'Join a group of fellow travelers to explore the famous spots of Bohol at an affordable price.',
      image: panglaoImage,
      price: 980,
      bookings: '1500++ booked',
      inclusions: [
        'Fully Air Conditioned Vehicle w/ Fuel',
        'Pick up Guests @ Any Point Along Panglao and Tagbilaran City',
        'Tour Duration (08:00AM – 05:00PM)',
      ],
      exclusions: [
        'Entrance Fees',
        'Lunch @ Loboc or Loay River Floating Restaurant (Cruise)',
      ],
    },
    {
      id: 3,
      tour_type: 'island',
      title: 'Panglao Island Hopping',
      location: 'Panglao, Bohol',
      description: 'Enjoy an exciting island hopping tour around Panglao. Experience dolphin watching, snorkeling, and visit pristine islands.',
      image: panglaoImage,
      price: 1100,
      bookings: '2000++ booked',
      inclusions: [
        'Fully Air Conditioned Vehicle w/ Fuel',
        '1 Captain Boatman',
        'Snorkeling Gears',
        'Complete Set of Life Vest / Passenger',
        'Environmental Fee',
        'Lunch',
      ],
      exclusions: [
        'All rides and activities not mentioned.',
      ],
    },
    {
      id: 4,
      tour_type: 'firefly',
      title: 'Firefly Watching / Whale Shark Watching',
      location: 'Bohol, Philippines',
      description: 'Experience the magic of watching fireflies in the evening or go whale shark watching during the day.',
      image: panglaoImage,
      price: 980,
      bookings: '500++ booked',
      inclusions: [
        'Fully Air Conditioned Vehicle w/ Fuel',
        'Pick up & Dropping Point: Panglao Beach Area',
        'Complete Set of Life Vest / Passenger',
      ],
      exclusions: [
        'Snorkeling Gears',
        'Lunch',
      ],
    },
  ];
  
  export default toursData;
  