import React from 'react';
import classes from './PublicFooter.module.css';
import FacebookIcon from '../../../assets/icons/facebook.png';
import InstagramIcon from '../../../assets/icons/instagram.png';
import YouTubeIcon from '../../../assets/icons/youtube.png';
import TikTokIcon from '../../../assets/icons/tiktok.png';

const PublicFooter = () => {
  return (
    <footer className={classes.footer}>
      <div className={classes.footerContainer}>
        <div className={classes.topSection}>
          <div className={classes.contact}>
            <h3>Get In Touch</h3>
            <div className={classes.socialMediaIcons}>
              <a href="https://www.facebook.com/jl.travel.agency17/" target="_blank" rel="noopener noreferrer">
                <img src={FacebookIcon} alt="Facebook" className={classes.icon} />
              </a>
              <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
                <img src={InstagramIcon} alt="Instagram" className={classes.icon} />
              </a>
              <a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer">
                <img src={YouTubeIcon} alt="YouTube" className={classes.icon} />
              </a>
              <a href="https://www.tiktok.com" target="_blank" rel="noopener noreferrer">
                <img src={TikTokIcon} alt="TikTok" className={classes.icon} />
              </a>
            </div>
          </div>
          <div className={classes.links}>
            <a href="#contact" className={classes.linkItem}>
              <i className={`fas fa-envelope ${classes.icon}`}></i> Contact Us
            </a>
            <a href="#signup" className={classes.linkItem}>
              <i className={`fas fa-sign-in-alt ${classes.icon}`}></i> Sign up for our best deals!
            </a>
          </div>
        </div>
        <div className={classes.middleSection}>
          <div className={classes.column}>
            <h4>Destinations</h4>
            <ul>
              <li><a href="#all">All destinations</a></li>
              <li><a href="#">Boracay</a></li>
              <li><a href="#">Cebu</a></li>
              <li><a href="#">Cagayan</a></li>
            </ul>
          </div>
          <div className={classes.column}>
            <h4>Useful Links</h4>
            <ul>
              <li><a href="#">Travel Advisories</a></li>
              <li><a href="#">Visa Information</a></li>
              <li><a href="#car-rental">Car Rental</a></li>
              <li><a href="#">Accommodation Booking</a></li>
              <li><a href="#">Travel Insurance</a></li>
            </ul>
          </div>
          <div className={classes.column}>
            <h4>Information</h4>
            <ul>
              <li><a href="#cookies-policy">Cookies Policy</a></li>
              <li><a href="/privacy-policy">Privacy Policy</a></li>
              <li><a href="/terms-and-conditions">Terms and Conditions</a></li>
              <li><a href="/terms-of-use">Terms of use</a></li>
            </ul>
          </div>
        </div>
        <div className={classes.bottomSection}>
          <div className={classes.social}>
            <a href="#facebook"><i className="fab fa-facebook-f"></i></a>
            <a href="#twitter"><i className="fab fa-twitter"></i></a>
          </div>
          <p>© 2024 JL Travel Agency All rights reserved</p>
          {/* <p>Department of Tourism Accreditation Certificate # 00000000</p> */}
        </div>
      </div>
    </footer>
  );
};

export default PublicFooter;


// import React from 'react';
// import classes from './PublicFooter.module.css';

// const PublicFooter = () => {
//   return (
//     <footer className={classes.footer}>
//       <div className={classes.footerContainer}>
//         <div className={classes.topSection}>
//           <div className={classes.contact}>
//             <h3>Get In Touch</h3>
//             <p className={classes.phone}>+63 938 609 1908</p>
//           </div>
//           <div className={classes.links}>
//             <a href="#contact" className={classes.linkItem}>
//               <i className={`fas fa-envelope ${classes.icon}`}></i> Contact Us
//             </a>
//             <a href="#signup" className={classes.linkItem}>
//               <i className={`fas fa-sign-in-alt ${classes.icon}`}></i> Sign up for our best deals!
//             </a>
//           </div>
//         </div>
//         <div className={classes.middleSection}>
//           <div className={classes.column}>
//             <h4>Destinations</h4>
//             <ul>
//               <li><a href="#all">All destinations</a></li>
//               <li><a href="#croatia">Boracay</a></li>
//               <li><a href="#cuba">Cebu</a></li>
//               <li><a href="#domrep">Cagayan</a></li>
//             </ul>
//           </div>
//           <div className={classes.column}>
//             <h4>Useful Links</h4>
//             <ul>
//               <li><a href="#">Travel Advisories</a></li>
//               <li><a href="#cibtvisas">Visa Information</a></li>
//               <li><a href="#car-rental">Car Rental</a></li>
//               <li><a href="#cruises">Accommodation Booking</a></li>
//               <li><a href="#e-brochures">Travel Insurance</a></li>
//             </ul>
//           </div>
//           <div className={classes.column}>
//             <h4>Information</h4>
//             <ul>
//               <li><a href="#cookies-policy">Cookies Policy</a></li>
//               <li><a href="/privacy-policy">Privacy Policy</a></li>
//               <li><a href="/terms-and-conditions">Terms and Conditions</a></li>
//               <li><a href="/terms-of-use">Terms of use</a></li>
//             </ul>
//           </div>
//         </div>
//         <div className={classes.bottomSection}>
//           <div className={classes.social}>
//             <a href="#facebook"><i className="fab fa-facebook-f"></i></a>
//             <a href="#twitter"><i className="fab fa-twitter"></i></a>
//           </div>
//           <p>© 2024 JL Travel Agency All rights reserved</p>
//           <p>Travel Registration # 00000000</p>
//         </div>
//       </div>
//     </footer>
//   );
// };

// export default PublicFooter;



// import React from 'react';
// import classes from './PublicFooter.module.css';
// import Logo from '../../../assets/image/logo/navbar.png';
// import PhoneIcon from '../../../assets/icons/phone.png';
// import EmailIcon from '../../../assets/icons/email.png';
// import FacebookIcon from '../../../assets/icons/facebook.png';
// import InstagramIcon from '../../../assets/icons/instagram.png';
// import YouTubeIcon from '../../../assets/icons/youtube.png';
// import TikTokIcon from '../../../assets/icons/tiktok.png';


// const PublicFooter = () => {
//     return (
//         <div className={classes['footer-section']}>

//             <div className={classes['crafted-by']}>
//                 Crafted by <a href="https://428am.com" target="_blank" rel="noopener noreferrer">428AM</a>
//             </div>
//         </div>
//     );
// };

// export default PublicFooter;

